import { LocalStorageInterface } from '@/interfaces/LocalStorageInterface';
import { User } from '@/types/userTypes';

export default class Storage {
    private readonly storage: LocalStorageInterface;
  
    public constructor(getStorage = (): LocalStorageInterface => window.localStorage) {
      this.storage = getStorage();
    }
    public get(key: string): string | null | object {
      return this.storage.getItem(key);
    }
    public set(key: string, value: User): void {
      this.storage.setItem(key, JSON.stringify(value));
    }
    public clearItem(key: string): void {
      this.storage.removeItem(key);
    }
  }